import { Trans } from 'react-i18next';

import { PasswordField } from '~/features/form/field/form-field/password-field';

export const Password = ({ className }: Readonly<{ className?: string }>) => (
  <PasswordField
    className={className}
    name='password'
    label={
      <Trans ns='field' i18nKey='password.default'>
        Password
      </Trans>
    }
    autoComplete='current-password'
  />
);
